@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Bestigia";
    src: url("./assets/fonts/Bestigia-Regular.ttf");
}

html {
    scroll-behavior: smooth;
}